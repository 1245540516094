var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"0","color":"transparent"}},[_c('v-card-title',[_vm._v(" Панель состояния ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('StatisitcCard',_vm._b({},'StatisitcCard',{
                title: 'Заказов',
                icon: 'mdi-book-account-outline',
                amount: _vm.orders,
                action: 'Подробнее...',
                link: '/orders'
},false))],1),_c('v-col',{attrs:{"cols":"4"}},[_c('StatisitcCard',_vm._b({},'StatisitcCard',{
                title: 'Оплачено',
                icon: 'mdi-credit-card-outline',
                amount: _vm.sells,
                action: 'Подробнее...',
                link: '/orders'
},false))],1),_c('v-col',{attrs:{"cols":"4"}},[_c('StatisitcCard',_vm._b({},'StatisitcCard',{
                title: 'Уникальных клиентов',
                icon: 'mdi-account',
                amount: _vm.clients,
                action: 'Подробнее...',
                link: '/orders'
},false))],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('OrdersHistory',{attrs:{"icon":"mdi-calendar-account","name":"Активность в админке","headers":[
                  {
                      text: 'Дата',
                      sortable: false,
                      value: 'Datetime'
                  },
                  {
                      text: 'Действие',
                      value: 'Action',
                      sortable: false
                  },
                  {
                      text: 'Имя пользователя',
                      value: 'Name',
                      sortable: false
                  }
              ],"history":_vm.journal}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('OrdersHistory',{attrs:{"icon":"mdi-book-account-outline","name":"Последние заказы","is-orders":"true","headers":[
                  {
                      text: 'Номер заказа',
                      key: 'Order',
                      sortable: false,
                      value: '@Order'
                  },
                  {
                      text: 'Сумма',
                      value: 'Amount',
                      sortable: false
                  },
                  {
                      text: 'Дата',
                      value: 'Date',
                      sortable: false
                  },
                  {
                      text: 'Номер телефона',
                      value: 'Phone',
                      sortable: false
                  }
              ],"history":_vm.lastOrders}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('OrdersHistory',{attrs:{"icon":"mdi-cellphone","name":"Ожидают звонка","is-orders":"true","headers":[
                  {
                      text: 'Номер заказа',
                      value: '@Order',
                      sortable: false
                  },
                  {
                      text: 'Дата',
                      value: 'Date',
                      sortable: false
                  },
                  {
                      text: 'Номер телефона',
                      value: 'Phone',
                      sortable: false
                  }
              ],"history":_vm.waitCalls}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }